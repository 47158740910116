import React, { useState } from 'react';
import { MinimalButton, Spinner, TextBox } from '@react-pdf-viewer/core';
import { NextIcon, PreviousIcon } from '@react-pdf-viewer/search';

const SEARCH_STATUS = {
	NotSearchedYet: 0,
	Searching: 1,
	FoundResults: 2,
};

const SearchPlugin = ({ searchPluginInstance, theme }) => {
	const [searchStatus, setSearchStatus] = useState(
		SEARCH_STATUS.NotSearchedYet
	);
	const [matches, setMatches] = useState([]);

	const { Search, clearHighlights } = searchPluginInstance;

	const limpiarBusqueda = () => {
		clearHighlights();
		setMatches([]);
		setSearchStatus(SEARCH_STATUS.NotSearchedYet);
	};

	const renderMatchSample = (match) => {
		//  match.startIndex    match.endIndex
		//      |                       |
		//      ▼                       ▼
		//  ....[_____props.keyword_____]....

		const wordsBefore = match.pageText.substr(match.startIndex - 20, 20);
		let words = wordsBefore.split(' ');
		words.shift();
		const begin = words.length === 0 ? wordsBefore : words.join(' ');

		const wordsAfter = match.pageText.substr(match.endIndex, 60);
		words = wordsAfter.split(' ');
		words.pop();
		const end = words.length === 0 ? wordsAfter : words.join(' ');

		return (
			<div>
				{begin}
				<span className={`bg-warning text-black`}>
					{match.pageText.substring(match.startIndex, match.endIndex)}
				</span>
				{end}
			</div>
		);
	};

	return (
		<Search>
			{(renderSearchProps) => {
				const {
					currentMatch,
					keyword,
					setKeyword,
					jumpToMatch,
					jumpToNextMatch,
					jumpToPreviousMatch,
					search,
				} = renderSearchProps;

				const handleSearchKeyDown = (e) => {
					if (e.key === 'Enter' && keyword) {
						setSearchStatus(SEARCH_STATUS.Searching);
						search().then((matches) => {
							setSearchStatus(SEARCH_STATUS.FoundResults);
							setMatches(matches);
						});
					}
				};

				return (
					<div className='d-flex flex-column h-100 w-100 overflow-hidden'>
						<div style={{ padding: '.5rem' }}>
							<div className='position-relative'>
								<TextBox
									placeholder='Search keywords'
									value={keyword}
									onChange={setKeyword}
									onKeyDown={handleSearchKeyDown}
								/>
								{searchStatus === SEARCH_STATUS.Searching && (
									<div
										className='d-flex align-items-center position-absolute top-0 bottom-0'
										style={{
											right: '.5rem',
										}}
									>
										<Spinner size='1.5rem' />
									</div>
								)}
								{searchStatus ===
									SEARCH_STATUS.FoundResults && (
									<div
										onClick={limpiarBusqueda}
										className='my-3'
										style={{ cursor: 'pointer' }}
									>
										Clean Search
									</div>
								)}
							</div>
						</div>
						{searchStatus === SEARCH_STATUS.FoundResults && (
							<>
								{matches.length === 0 && (
									<div
										style={{
											padding: '0 .5rem',
										}}
									>
										Not Found
									</div>
								)}
								{matches.length > 0 && (
									<>
										<div
											className='d-flex align-items-center'
											style={{
												padding: '.5rem',
											}}
										>
											<div
												className={`${
													theme === 'dark'
														? 'text-white'
														: 'text-black'
												}`}
												style={{
													fontSize: '.8rem',
													marginRight: '.5rem',
												}}
											>
												Found {matches.length} results
											</div>
											<div
												style={{
													marginLeft: 'auto',
													marginRight: '.5rem',
												}}
											>
												<MinimalButton
													onClick={
														jumpToPreviousMatch
													}
												>
													<PreviousIcon />
												</MinimalButton>
											</div>
											<MinimalButton
												onClick={jumpToNextMatch}
											>
												<NextIcon />
											</MinimalButton>
										</div>
										<div
											style={{
												borderTop: `1px solid ${
													theme === 'dark'
														? 'rgba(255, 255, 255, .5)'
														: 'rgba(0, 0, 0, .5)'
												}`,
												flex: 1,
												overflow: 'auto',
												padding: '.5rem 1rem',
											}}
										>
											{matches.map((match, index) => (
												<div
													key={index}
													style={{ margin: '1rem 0' }}
												>
													<div
														className='d-flex justify-content-between'
														style={{
															marginBottom:
																'.5rem',
														}}
													>
														<div>#{index + 1}</div>
														<div
															style={{
																fontSize:
																	'.8rem',
																textAlign:
																	'right',
															}}
														>
															Page{' '}
															{match.pageIndex +
																1}
														</div>
													</div>
													<div
														style={{
															backgroundColor:
																currentMatch ===
																index + 1
																	? 'rgba(0, 0, 0, .1)'
																	: '',
															border: '1px solid rgba(0, 0, 0, .2)',
															borderRadius:
																'.25rem',
															cursor: 'pointer',
															overflowWrap:
																'break-word',
															padding: '.5rem',
														}}
														onClick={() =>
															jumpToMatch(
																index + 1
															)
														}
													>
														{renderMatchSample(
															match
														)}
													</div>
												</div>
											))}
										</div>
									</>
								)}
							</>
						)}
					</div>
				);
			}}
		</Search>
	);
};

export default SearchPlugin;
