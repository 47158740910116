import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Typography } from "@material-ui/core";
import clsx from 'clsx';
const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: "#f2f3f6",//theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    modalBody: {
        backgroundColor: 'white',
        position: 'relative',
        padding: '40px',
        width: '100%',
        maxWidth: "750px",
        maxHeight: "410px",
        overflowY: 'auto'

    },
    modalBodyEmbededVideo:{
        /*[theme.breakpoints.up('sm')]: { 
            width:'100% !important',
            height:'80% !important',
        },*/        
        [theme.breakpoints.down('sm')]: { 
            width:'100% !important',
            height:'200px !important',
        },       
         [theme.breakpoints.down('md')]: { 
            width:'375px !important',
            height:'211px !important',
        }, 
        [theme.breakpoints.up('md')]: { 
            width:'100% !important',
            height:'410px !important',
        },
    },
    closeModalButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        fontSize: '1.8rem',
        cursor: 'pointer',
        '&:hover': {
            color:'#FF5F5F'
        }
    },
}));

export default function FadeInModal(props) {
    const classes = useStyles();
    const { open, handleClose, isYoutubeEmbed } = props;
    return (
        
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={open}>
                    <div className={clsx(classes.modalBody, {[classes.modalBodyEmbededVideo]:isYoutubeEmbed})} >
                        <span className={classes.closeModalButton} onClick={handleClose}>
                            &#10005;
                        </span>
                        <Typography variant='h1'>{props.modalTitle}</Typography>    
                        {props.children}
                    </div>
                </Fade>
            </Modal>
        
    )
}